import React from 'react'
import { Layout, Stack, Main, Sidebar, PreFooter } from '@layout'
import CardList from '@components/CardList'
import Sticky from '@components/Sticky'
import Divider from '@components/Divider'
import Pagination from '@components/Pagination'
import Seo from '@widgets/Seo'
import Tags from '@widgets/Tags'
import Sponsor from '@widgets/Sponsor'
import NewsletterCompact from '@widgets/NewsletterCompact'
import Social from '@widgets/Social'
import { useBlogTags } from '@helpers-blog'

const Posts = ({
  data: { paginatedPosts = {}, featuredPosts = {}, recentPosts = {} },
  ...props
}) => {
  const { pageContext: { services = {}, basePath } = {} } = props
  const tags = useBlogTags()

  return (
    <Layout {...props}>
      <Seo title='Home' />
      <Divider />
      <Stack>
        <Main>
          <CardList
            nodes={featuredPosts.nodes}
            limit={3}
            variant='horizontal-cover'
            slider
            fade
            controlPosition='over'
            loading='eager'
            omitCategory
          />
          <Divider />
          <CardList
            variant={['horizontal-md', 'horizontal']}
            nodes={paginatedPosts.nodes}
            columns={[1]}
          />
        </Main>
        <Sidebar>
          <CardList
            nodes={recentPosts.nodes}
            limit={4}
            variant='horizontal-aside'
            title='Recently Published'
            omitMedia
            omitCategory
            aside
          />
          <Divider />
          <Sponsor />
          <Divider />
          <Tags tags={tags} />
          <Divider />
          <Social />
          <Sticky>
            {services.mailchimp && (
              <>
                <Divider />
                <NewsletterCompact />
              </>
            )}
          </Sticky>
        </Sidebar>
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination {...paginatedPosts.pageInfo} basePath={basePath} />
      </PreFooter>
    </Layout>
  )
}

export default Posts
